import { useEffect, useRef, useState } from "react"
import Atom from "../atom/Atom"
import {changeStatus} from "../atom/Atom"
import { initAI, predict } from "../atom/libs/OpenAI"
import { save, load, cloudStorage } from "../utils/Local";

import { Prompt, Dialog, YN } from "./PopUp";

const apiKey = "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";
let rawMssgs, setRawMssgs;
let displayMssgs, setDisplayMssgs;
let acceptTerms, setAcceptTerms;
let reload, setReload;
let POPUP, setPOPUP;

const processTerms = e =>{
    // validations (email and tyc)
    let tyc = document.querySelector('#chat-tyc').checked;
    let mailing = document.querySelector('#chat-mailing').checked;
    let email = document.querySelector('#chat-email').value.toString();
    if(!tyc) return setPOPUP({type: 'dialog', title: 'Lo sentimos', message:'Aún no has aceptado nuestros Términos y Condiciones', btn: 'Aceptar', callback: e=> setPOPUP(undefined)});
    if(!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g)) return setPOPUP({type: 'dialog', title: 'Lo sentimos', message:'Aún no has ingresado un correo válido.', btn: 'Aceptar', callback: e=> setPOPUP(undefined)});
    // update accepted terms
    setAcceptTerms(true);
    setReload(false);
    // store user data locally
    save('user_email', email);
    save('user_tyc', tyc);
    // store user on cloud
    cloudStorage.insertUser(email, tyc, mailing);
}

const defaultMessages = [
    {
        role: 'assistant',
        content:( 
                <div>
                    Bienvenido a Sanolivar! Para hacer uso de nuestro <strong>asistente virtual</strong>, es necesario contar con su consentimiento. Razón por la cual solicitamos diligenciar el siguiente formulario.<hr/>
                    <fieldset>
                        <legend>Consentimiento</legend>
                        <label htmlFor="chat-email">Correo:</label> <input type="email" name="chat-email" id="chat-email" required placeholder="example@myemail.com"/><hr/>
                        <input type="checkbox" name="chat-tyc" id="chat-tyc" /> <label htmlFor="chat-tyc" required>Acepto las <a href="/es/tyc.html" target="_blank">Políticas de Tratamiento de Datos</a> de Sanolivar.</label><hr/>
                        <input type="checkbox" name="chat-mailing" id="chat-mailing" /> <label htmlFor="chat-mailing">Deseo recibir una copia de mi conversación por correo.</label><hr/>
                        <button onClick={processTerms}>
                            Aceptar
                        </button>
                    </fieldset>
                </div>
            )
    }
]

const regex = /(?:\[.*?\/\])/gm;
const md2html = input =>{
    if(input == '') return '';
    if(input == 'loading_anim') return '<p className="loading"><span></span></p>';
    // remove avatar commands
    input = input.replace(regex, '');
    // Convert titles
    input = input.replace(/^### (.*$)/gmi, '<strong>$1</strong>');
    input = input.replace(/^## (.*$)/gmi, '<strong>$1</strong>');
    input = input.replace(/^# (.*$)/gmi, '<strong>$1</strong>');

    // convert bold
    input = input.replace(/\*\*(.*?)\*\*/gmi, '<strong>$1</strong>');

    // convert lists
    input = input.replace(/^\s*[-+*]\s+(.*)/gmi, '<li>$1</li>');
    input = input.replace(/^\s*(\d+)\.\s+(.*)/gmi, '<li><strong>$1.</strong> $2</li>');

    // convert break lines
    input = input.replace(/\n/gmi, '<hr/>');

    return input.trim();
}

/**
 * Clone object
 * @param {object} src Object to be cloned
 * @returns {object} Cloned object
 */
const clone = (src) =>{
    return JSON.parse(JSON.stringify(src))
}

export default ()=>{
    // maybe atom, chat, minimized
    const [mode, setMode] = useState('minimized');
    const [listening, setListening] = useState(false);
    [acceptTerms, setAcceptTerms] = useState(false);
    [reload, setReload] = useState(true);
    [rawMssgs, setRawMssgs] = useState([]);
    [displayMssgs, setDisplayMssgs] = useState(defaultMessages);
    [POPUP, setPOPUP] = useState(undefined);

    const [thinking, setThinking] = useState('a');
    const chatblock = useRef(null);

    useEffect(()=>{
        // load tyc
        load('user_tyc').then(r=> setAcceptTerms(r));
        // load history
        load('chat-history').then(r=> setRawMssgs(r));
    },[])
    
    useEffect(()=>{
        if(!acceptTerms) return;
        // init AI engine
        initAI(apiKey, 'sanolivar');
        // update chat window
        if(!reload)setRawMssgs([{role: 'assistant', content: `Hola! Bienvenido a Sanolivar! \nSoy Atom, tu asistente personal y estaré encantado de ayudarte. ¿En que puedo servirte?`}]);
    },[acceptTerms]);

    useEffect(()=>{
        if(mode == 'atom') setListening(true);
        else setListening(false);

        chatAutoFlow();
    },[mode]);

    useEffect(()=>{
        if(rawMssgs.length == 0) return;
        // store raw messages
        save('chat-history', rawMssgs);
        let newDispMssgs = [];
        rawMssgs.forEach(mssg => {
            newDispMssgs.push({
                role: mssg.role,
                content: <div dangerouslySetInnerHTML={{__html: md2html(mssg.content)}}/>
            })
        });
        setDisplayMssgs(newDispMssgs);
        chatAutoFlow();
    },[rawMssgs])


    const chatAutoFlow = ()=>{
        //autoscroll chatbot
        if(chatblock.current == undefined) return;
        setTimeout(() => {
            chatblock.current.scrollTop = chatblock.current.scrollHeight + 100;
        }, 50);
    }


    const requestPrediction = (userMssg, lang, callbackFunction)=>{
        // set avatar thinking
        setThinking('💡');
        setTimeout(() => {
            setThinking('a');
        }, 1500);
        predict(userMssg, 'es', result=>{
            // avatar actions validated
            if(callbackFunction != undefined) callbackFunction(result);
            // chat actions
            let raws = clone(rawMssgs);
            if(raws[raws.length - 1].content == 'loading_anim') raws[raws.length - 1].content = result;
            else raws[raws.length - 1].content += result;
            setRawMssgs(raws);
        })
        // update user message
        setRawMssgs([...rawMssgs, {
            role: 'user',
            content: userMssg
        }, {role: 'assistant', content: 'loading_anim'}])
        chatAutoFlow();
    }

    return (
        <div className="chatbot">
            {
                mode == 'atom' ?
                (
                    <div>
                        <div className="atom-help">
                            <i title="Ayuda" className="material-symbols-outlined help-btn">help</i>
                            <ul className="help">
                                <li><strong>Ayuda</strong></li>
                                <li>- Interactuar con Atom es muy sencillo: solo di su nombre seguido de la pregunta que desees hacer.</li>
                                <li>- Después de mencionar "Atom", continúa con tu consulta sin pausas para obtener una respuesta fluida.</li>
                                <li>- Explica tu problema o caso de uso a Atom, y él te proporcionará la mejor solución.</li>
                                <li>- Si necesitas revisar algún dato o no escuchaste bien la respuesta de Atom, puedes consultar la ventana de chat.</li>
                                <li>- Si aún no conoces nuestros <strong>productos</strong> y <strong>servicios</strong>, no dudes en preguntar por ellos.</li>
                                <li>- También puedes solicitar asistencia técnica en cualquier momento.</li>
                            </ul>
                        </div>
                        <span className="indicator">{}</span>
                        <Atom predict={requestPrediction} lstn={listening}/>
                    </div>
                ):
                (<></>)
            }
            {
                mode == 'chat' ? 
                (
                    <article>
                        <header>
                            <div>
                                <i title="Ayuda" className="material-symbols-outlined help-btn">help</i>
                                <ul className="help">
                                    <li><strong>Ayuda</strong></li>
                                    <li>- Puedes cambiar al modo de avatar interactivo haciendo click en el botón <i style={{color: 'black', fontSize: '1rem'}} className="material-symbols-outlined">mic</i>.</li>
                                    <li>- Explica tu problema o caso de uso a Atom, y él te proporcionará la mejor solución.</li>
                                    <li>- Si aún no conoces nuestros <strong>productos</strong> y <strong>servicios</strong>, no dudes en preguntar por ellos.</li>
                                    <li>- También puedes solicitar asistencia técnica en cualquier momento.</li>
                                </ul>
                                <i title="Modo voz" className="material-symbols-outlined" onClick={e=> setMode('atom')}>mic</i>
                                <i title="Minimizar" className="material-symbols-outlined" onClick={e=> setMode('minimized')}>close_fullscreen</i>
                            </div>
                        </header>
                        <ul ref={chatblock} id="chat-block">
                            {
                                displayMssgs.map((m,i)=>(
                                    <li key={`message-${m.role+i}`} className={m.role == 'assistant' ? 'bot': 'user'} >{m.content}</li>
                                ))
                            }
                        </ul>
                        <footer>
                            <input type="text" name="chat-message" id="chat-message" placeholder="Escribe tu mensaje aquí" />
                            <i className="material-symbols-outlined" onClick={
                                e=>{
                                    //retrieve message
                                    let msg = document.querySelector('#chat-message').value;
                                    if(msg == '') return;
                                    // request prediction
                                    requestPrediction(msg);
                                    // clean message box
                                    document.querySelector('#chat-message').value = '';
                                }
                            }>send</i>
                        </footer>
                        <div style={{display: 'none'}}>
                            <Atom predict={requestPrediction} lstn={listening}/>
                        </div>
                    </article>
                )
                :(
                    <div className="buttons">
                        <button title="Avatar Interactivo"
                            onClick={e =>{
                                if(mode == 'atom') setMode('minimized');
                                else {
                                    if(!acceptTerms) return setPOPUP({
                                        type: 'dialog',
                                        title: 'Lo sentimos',
                                        message: 'Aún no has aceptado nuestros Términos y Condiciones. Por favor abre la ventana de chat primero.',
                                        btn: 'Aceptar',
                                        callback: e=> setPOPUP(undefined)
                                    });
                                    setMode('atom');
                                }
                            }}>
                                <img src="/atom-assets/atom-icon.webp" alt="icono Atom avatar" />
                        </button>
                        <button 
                            title="Chatbot"
                            onClick={e =>{
                                if(mode == 'chat') setMode('minimized');
                                else setMode('chat');
                            }}
                            >
                            <i className='material-symbols-outlined'>chat</i>
                        </button>
                        <div style={{display: 'none'}}>
                            <Atom predict={requestPrediction} lstn={listening}/>
                        </div>
                    </div>
                )
            }
            {
              POPUP == undefined ? (<></>)
              : POPUP.type == 'dialog' ? (<Dialog title={POPUP.title} message={POPUP.message} btnText={POPUP.btn} callback={POPUP.callback} />)
              : POPUP.type == 'prompt' ? (<Prompt title={POPUP.title} message={POPUP.message} callback={POPUP.callback}/>)
              : (<YN title={POPUP.title} message={POPUP.message}  callback={POPUP.callback} />)
            }
        </div>
    )
}